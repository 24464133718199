export const countries = [
  {
    id: 1,
    name: "Afghanistan",
    iso3: "AFG",
    currency: "AFN",
    currency_symbol: "؋",
  },
  {
    id: 2,
    name: "Aland Islands",
    iso3: "ALA",
    currency: "EUR",
    currency_symbol: "€",
  },
  {
    id: 3,
    name: "Albania",
    iso3: "ALB",
    currency: "ALL",
    currency_symbol: "Lek",
  },
  {
    id: 4,
    name: "Algeria",
    iso3: "DZA",
    currency: "DZD",
    currency_symbol: "دج",
  },
  {
    id: 5,
    name: "American Samoa",
    iso3: "ASM",
    currency: "USD",
    currency_symbol: "$",
  },
  {
    id: 6,
    name: "Andorra",
    iso3: "AND",
    currency: "EUR",
    currency_symbol: "€",
  },
  {
    id: 7,
    name: "Angola",
    iso3: "AGO",
    currency: "AOA",
    currency_symbol: "Kz",
  },
  {
    id: 8,
    name: "Anguilla",
    iso3: "AIA",
    currency: "XCD",
    currency_symbol: "$",
  },
  {
    id: 9,
    name: "Antarctica",
    iso3: "ATA",
    currency: "AAD",
    currency_symbol: "$",
  },
  {
    id: 10,
    name: "Antigua And Barbuda",
    iso3: "ATG",
    currency: "XCD",
    currency_symbol: "$",
  },
  {
    id: 11,
    name: "Argentina",
    iso3: "ARG",
    currency: "ARS",
    currency_symbol: "$",
  },
  {
    id: 12,
    name: "Armenia",
    iso3: "ARM",
    currency: "AMD",
    currency_symbol: "֏",
  },
  {
    id: 13,
    name: "Aruba",
    iso3: "ABW",
    currency: "AWG",
    currency_symbol: "ƒ",
  },
  {
    id: 14,
    name: "Australia",
    iso3: "AUS",
    currency: "AUD",
    currency_symbol: "$",
  },
  {
    id: 15,
    name: "Austria",
    iso3: "AUT",
    currency: "EUR",
    currency_symbol: "€",
  },
  {
    id: 16,
    name: "Azerbaijan",
    iso3: "AZE",
    currency: "AZN",
    currency_symbol: "m",
  },
  {
    id: 18,
    name: "Bahrain",
    iso3: "BHR",
    currency: "BHD",
    currency_symbol: ".د.ب",
  },
  {
    id: 19,
    name: "Bangladesh",
    iso3: "BGD",
    currency: "BDT",
    currency_symbol: "৳",
  },
  {
    id: 20,
    name: "Barbados",
    iso3: "BRB",
    currency: "BBD",
    currency_symbol: "Bds$",
  },
  {
    id: 21,
    name: "Belarus",
    iso3: "BLR",
    currency: "BYN",
    currency_symbol: "Br",
  },
  {
    id: 22,
    name: "Belgium",
    iso3: "BEL",
    currency: "EUR",
    currency_symbol: "€",
  },
  {
    id: 23,
    name: "Belize",
    iso3: "BLZ",
    currency: "BZD",
    currency_symbol: "$",
  },
  {
    id: 24,
    name: "Benin",
    iso3: "BEN",
    currency: "XOF",
    currency_symbol: "CFA",
  },
  {
    id: 25,
    name: "Bermuda",
    iso3: "BMU",
    currency: "BMD",
    currency_symbol: "$",
  },
  {
    id: 26,
    name: "Bhutan",
    iso3: "BTN",
    currency: "BTN",
    currency_symbol: "Nu.",
  },
  {
    id: 27,
    name: "Bolivia",
    iso3: "BOL",
    currency: "BOB",
    currency_symbol: "Bs.",
  },
  {
    id: 155,
    name: "Bonaire, Sint Eustatius and Saba",
    iso3: "BES",
    currency: "USD",
    currency_symbol: "$",
  },
  {
    id: 28,
    name: "Bosnia and Herzegovina",
    iso3: "BIH",
    currency: "BAM",
    currency_symbol: "KM",
  },
  {
    id: 29,
    name: "Botswana",
    iso3: "BWA",
    currency: "BWP",
    currency_symbol: "P",
  },
  {
    id: 30,
    name: "Bouvet Island",
    iso3: "BVT",
    currency: "NOK",
    currency_symbol: "kr",
  },
  {
    id: 31,
    name: "Brazil",
    iso3: "BRA",
    currency: "BRL",
    currency_symbol: "R$",
  },
  {
    id: 32,
    name: "British Indian Ocean Territory",
    iso3: "IOT",
    currency: "USD",
    currency_symbol: "$",
  },
  {
    id: 33,
    name: "Brunei",
    iso3: "BRN",
    currency: "BND",
    currency_symbol: "B$",
  },
  {
    id: 34,
    name: "Bulgaria",
    iso3: "BGR",
    currency: "BGN",
    currency_symbol: "Лв.",
  },
  {
    id: 35,
    name: "Burkina Faso",
    iso3: "BFA",
    currency: "XOF",
    currency_symbol: "CFA",
  },
  {
    id: 36,
    name: "Burundi",
    iso3: "BDI",
    currency: "BIF",
    currency_symbol: "FBu",
  },
  {
    id: 37,
    name: "Cambodia",
    iso3: "KHM",
    currency: "KHR",
    currency_symbol: "KHR",
  },
  {
    id: 38,
    name: "Cameroon",
    iso3: "CMR",
    currency: "XAF",
    currency_symbol: "FCFA",
  },
  {
    id: 39,
    name: "Canada",
    iso3: "CAN",
    currency: "CAD",
    currency_symbol: "$",
  },
  {
    id: 40,
    name: "Cape Verde",
    iso3: "CPV",
    currency: "CVE",
    currency_symbol: "$",
  },
  {
    id: 41,
    name: "Cayman Islands",
    iso3: "CYM",
    currency: "KYD",
    currency_symbol: "$",
  },
  {
    id: 42,
    name: "Central African Republic",
    iso3: "CAF",
    currency: "XAF",
    currency_symbol: "FCFA",
  },
  {
    id: 43,
    name: "Chad",
    iso3: "TCD",
    currency: "XAF",
    currency_symbol: "FCFA",
  },
  {
    id: 44,
    name: "Chile",
    iso3: "CHL",
    currency: "CLP",
    currency_symbol: "$",
  },
  {
    id: 45,
    name: "China",
    iso3: "CHN",
    currency: "CNY",
    currency_symbol: "¥",
  },
  {
    id: 46,
    name: "Christmas Island",
    iso3: "CXR",
    currency: "AUD",
    currency_symbol: "$",
  },
  {
    id: 47,
    name: "Cocos (Keeling) Islands",
    iso3: "CCK",
    currency: "AUD",
    currency_symbol: "$",
  },
  {
    id: 48,
    name: "Colombia",
    iso3: "COL",
    currency: "COP",
    currency_symbol: "$",
  },
  {
    id: 49,
    name: "Comoros",
    iso3: "COM",
    currency: "KMF",
    currency_symbol: "CF",
  },
  {
    id: 50,
    name: "Congo",
    iso3: "COG",
    currency: "XAF",
    currency_symbol: "FC",
  },
  {
    id: 52,
    name: "Cook Islands",
    iso3: "COK",
    currency: "NZD",
    currency_symbol: "$",
  },
  {
    id: 53,
    name: "Costa Rica",
    iso3: "CRI",
    currency: "CRC",
    currency_symbol: "₡",
  },
  {
    id: 54,
    name: "Cote D'Ivoire (Ivory Coast)",
    iso3: "CIV",
    currency: "XOF",
    currency_symbol: "CFA",
  },
  {
    id: 55,
    name: "Croatia",
    iso3: "HRV",
    currency: "HRK",
    currency_symbol: "kn",
  },
  {
    id: 56,
    name: "Cuba",
    iso3: "CUB",
    currency: "CUP",
    currency_symbol: "$",
  },
  {
    id: 249,
    name: "Curaçao",
    iso3: "CUW",
    currency: "ANG",
    currency_symbol: "ƒ",
  },
  {
    id: 57,
    name: "Cyprus",
    iso3: "CYP",
    currency: "EUR",
    currency_symbol: "€",
  },
  {
    id: 58,
    name: "Czech Republic",
    iso3: "CZE",
    currency: "CZK",
    currency_symbol: "Kč",
  },
  {
    id: 51,
    name: "Democratic Republic of the Congo",
    iso3: "COD",
    currency: "CDF",
    currency_symbol: "FC",
  },
  {
    id: 59,
    name: "Denmark",
    iso3: "DNK",
    currency: "DKK",
    currency_symbol: "Kr.",
  },
  {
    id: 60,
    name: "Djibouti",
    iso3: "DJI",
    currency: "DJF",
    currency_symbol: "Fdj",
  },
  {
    id: 61,
    name: "Dominica",
    iso3: "DMA",
    currency: "XCD",
    currency_symbol: "$",
  },
  {
    id: 62,
    name: "Dominican Republic",
    iso3: "DOM",
    currency: "DOP",
    currency_symbol: "$",
  },
  {
    id: 63,
    name: "East Timor",
    iso3: "TLS",
    currency: "USD",
    currency_symbol: "$",
  },
  {
    id: 64,
    name: "Ecuador",
    iso3: "ECU",
    currency: "USD",
    currency_symbol: "$",
  },
  {
    id: 65,
    name: "Egypt",
    iso3: "EGY",
    currency: "EGP",
    currency_symbol: "ج.م",
  },
  {
    id: 66,
    name: "El Salvador",
    iso3: "SLV",
    currency: "USD",
    currency_symbol: "$",
  },
  {
    id: 67,
    name: "Equatorial Guinea",
    iso3: "GNQ",
    currency: "XAF",
    currency_symbol: "FCFA",
  },
  {
    id: 68,
    name: "Eritrea",
    iso3: "ERI",
    currency: "ERN",
    currency_symbol: "Nfk",
  },
  {
    id: 69,
    name: "Estonia",
    iso3: "EST",
    currency: "EUR",
    currency_symbol: "€",
  },
  {
    id: 70,
    name: "Ethiopia",
    iso3: "ETH",
    currency: "ETB",
    currency_symbol: "Nkf",
  },
  {
    id: 71,
    name: "Falkland Islands",
    iso3: "FLK",
    currency: "FKP",
    currency_symbol: "£",
  },
  {
    id: 72,
    name: "Faroe Islands",
    iso3: "FRO",
    currency: "DKK",
    currency_symbol: "Kr.",
  },
  {
    id: 73,
    name: "Fiji Islands",
    iso3: "FJI",
    currency: "FJD",
    currency_symbol: "FJ$",
  },
  {
    id: 74,
    name: "Finland",
    iso3: "FIN",
    currency: "EUR",
    currency_symbol: "€",
  },
  {
    id: 75,
    name: "France",
    iso3: "FRA",
    currency: "EUR",
    currency_symbol: "€",
  },
  {
    id: 76,
    name: "French Guiana",
    iso3: "GUF",
    currency: "EUR",
    currency_symbol: "€",
  },
  {
    id: 77,
    name: "French Polynesia",
    iso3: "PYF",
    currency: "XPF",
    currency_symbol: "₣",
  },
  {
    id: 78,
    name: "French Southern Territories",
    iso3: "ATF",
    currency: "EUR",
    currency_symbol: "€",
  },
  {
    id: 79,
    name: "Gabon",
    iso3: "GAB",
    currency: "XAF",
    currency_symbol: "FCFA",
  },
  {
    id: 80,
    name: "Gambia The",
    iso3: "GMB",
    currency: "GMD",
    currency_symbol: "D",
  },
  {
    id: 81,
    name: "Georgia",
    iso3: "GEO",
    currency: "GEL",
    currency_symbol: "ლ",
  },
  {
    id: 82,
    name: "Germany",
    iso3: "DEU",
    currency: "EUR",
    currency_symbol: "€",
  },
  {
    id: 83,
    name: "Ghana",
    iso3: "GHA",
    currency: "GHS",
    currency_symbol: "GH₵",
  },
  {
    id: 84,
    name: "Gibraltar",
    iso3: "GIB",
    currency: "GIP",
    currency_symbol: "£",
  },
  {
    id: 85,
    name: "Greece",
    iso3: "GRC",
    currency: "EUR",
    currency_symbol: "€",
  },
  {
    id: 86,
    name: "Greenland",
    iso3: "GRL",
    currency: "DKK",
    currency_symbol: "Kr.",
  },
  {
    id: 87,
    name: "Grenada",
    iso3: "GRD",
    currency: "XCD",
    currency_symbol: "$",
  },
  {
    id: 88,
    name: "Guadeloupe",
    iso3: "GLP",
    currency: "EUR",
    currency_symbol: "€",
  },
  {
    id: 89,
    name: "Guam",
    iso3: "GUM",
    currency: "USD",
    currency_symbol: "$",
  },
  {
    id: 90,
    name: "Guatemala",
    iso3: "GTM",
    currency: "GTQ",
    currency_symbol: "Q",
  },
  {
    id: 91,
    name: "Guernsey and Alderney",
    iso3: "GGY",
    currency: "GBP",
    currency_symbol: "£",
  },
  {
    id: 92,
    name: "Guinea",
    iso3: "GIN",
    currency: "GNF",
    currency_symbol: "FG",
  },
  {
    id: 93,
    name: "Guinea-Bissau",
    iso3: "GNB",
    currency: "XOF",
    currency_symbol: "CFA",
  },
  {
    id: 94,
    name: "Guyana",
    iso3: "GUY",
    currency: "GYD",
    currency_symbol: "$",
  },
  {
    id: 95,
    name: "Haiti",
    iso3: "HTI",
    currency: "HTG",
    currency_symbol: "G",
  },
  {
    id: 96,
    name: "Heard Island and McDonald Islands",
    iso3: "HMD",
    currency: "AUD",
    currency_symbol: "$",
  },
  {
    id: 97,
    name: "Honduras",
    iso3: "HND",
    currency: "HNL",
    currency_symbol: "L",
  },
  {
    id: 98,
    name: "Hong Kong S.A.R.",
    iso3: "HKG",
    currency: "HKD",
    currency_symbol: "$",
  },
  {
    id: 99,
    name: "Hungary",
    iso3: "HUN",
    currency: "HUF",
    currency_symbol: "Ft",
  },
  {
    id: 100,
    name: "Iceland",
    iso3: "ISL",
    currency: "ISK",
    currency_symbol: "kr",
  },
  {
    id: 101,
    name: "India",
    iso3: "IND",
    currency: "INR",
    currency_symbol: "₹",
  },
  {
    id: 102,
    name: "Indonesia",
    iso3: "IDN",
    currency: "IDR",
    currency_symbol: "Rp",
  },
  {
    id: 103,
    name: "Iran",
    iso3: "IRN",
    currency: "IRR",
    currency_symbol: "﷼",
  },
  {
    id: 104,
    name: "Iraq",
    iso3: "IRQ",
    currency: "IQD",
    currency_symbol: "د.ع",
  },
  {
    id: 105,
    name: "Ireland",
    iso3: "IRL",
    currency: "EUR",
    currency_symbol: "€",
  },
  {
    id: 106,
    name: "Israel",
    iso3: "ISR",
    currency: "ILS",
    currency_symbol: "₪",
  },
  {
    id: 107,
    name: "Italy",
    iso3: "ITA",
    currency: "EUR",
    currency_symbol: "€",
  },
  {
    id: 108,
    name: "Jamaica",
    iso3: "JAM",
    currency: "JMD",
    currency_symbol: "J$",
  },
  {
    id: 109,
    name: "Japan",
    iso3: "JPN",
    currency: "JPY",
    currency_symbol: "¥",
  },
  {
    id: 110,
    name: "Jersey",
    iso3: "JEY",
    currency: "GBP",
    currency_symbol: "£",
  },
  {
    id: 111,
    name: "Jordan",
    iso3: "JOR",
    currency: "JOD",
    currency_symbol: "ا.د",
  },
  {
    id: 112,
    name: "Kazakhstan",
    iso3: "KAZ",
    currency: "KZT",
    currency_symbol: "лв",
  },
  {
    id: 113,
    name: "Kenya",
    iso3: "KEN",
    currency: "KES",
    currency_symbol: "KSh",
  },
  {
    id: 114,
    name: "Kiribati",
    iso3: "KIR",
    currency: "AUD",
    currency_symbol: "$",
  },
  {
    id: 248,
    name: "Kosovo",
    iso3: "XKX",
    currency: "EUR",
    currency_symbol: "€",
  },
  {
    id: 117,
    name: "Kuwait",
    iso3: "KWT",
    currency: "KWD",
    currency_symbol: "ك.د",
  },
  {
    id: 118,
    name: "Kyrgyzstan",
    iso3: "KGZ",
    currency: "KGS",
    currency_symbol: "лв",
  },
  {
    id: 119,
    name: "Laos",
    iso3: "LAO",
    currency: "LAK",
    currency_symbol: "₭",
  },
  {
    id: 120,
    name: "Latvia",
    iso3: "LVA",
    currency: "EUR",
    currency_symbol: "€",
  },
  {
    id: 121,
    name: "Lebanon",
    iso3: "LBN",
    currency: "LBP",
    currency_symbol: "£",
  },
  {
    id: 122,
    name: "Lesotho",
    iso3: "LSO",
    currency: "LSL",
    currency_symbol: "L",
  },
  {
    id: 123,
    name: "Liberia",
    iso3: "LBR",
    currency: "LRD",
    currency_symbol: "$",
  },
  {
    id: 124,
    name: "Libya",
    iso3: "LBY",
    currency: "LYD",
    currency_symbol: "د.ل",
  },
  {
    id: 125,
    name: "Liechtenstein",
    iso3: "LIE",
    currency: "CHF",
    currency_symbol: "CHf",
  },
  {
    id: 126,
    name: "Lithuania",
    iso3: "LTU",
    currency: "EUR",
    currency_symbol: "€",
  },
  {
    id: 127,
    name: "Luxembourg",
    iso3: "LUX",
    currency: "EUR",
    currency_symbol: "€",
  },
  {
    id: 128,
    name: "Macau S.A.R.",
    iso3: "MAC",
    currency: "MOP",
    currency_symbol: "$",
  },
  {
    id: 129,
    name: "Macedonia",
    iso3: "MKD",
    currency: "MKD",
    currency_symbol: "ден",
  },
  {
    id: 130,
    name: "Madagascar",
    iso3: "MDG",
    currency: "MGA",
    currency_symbol: "Ar",
  },
  {
    id: 131,
    name: "Malawi",
    iso3: "MWI",
    currency: "MWK",
    currency_symbol: "MK",
  },
  {
    id: 132,
    name: "Malaysia",
    iso3: "MYS",
    currency: "MYR",
    currency_symbol: "RM",
  },
  {
    id: 133,
    name: "Maldives",
    iso3: "MDV",
    currency: "MVR",
    currency_symbol: "Rf",
  },
  {
    id: 134,
    name: "Mali",
    iso3: "MLI",
    currency: "XOF",
    currency_symbol: "CFA",
  },
  {
    id: 135,
    name: "Malta",
    iso3: "MLT",
    currency: "EUR",
    currency_symbol: "€",
  },
  {
    id: 136,
    name: "Man (Isle of)",
    iso3: "IMN",
    currency: "GBP",
    currency_symbol: "£",
  },
  {
    id: 137,
    name: "Marshall Islands",
    iso3: "MHL",
    currency: "USD",
    currency_symbol: "$",
  },
  {
    id: 138,
    name: "Martinique",
    iso3: "MTQ",
    currency: "EUR",
    currency_symbol: "€",
  },
  {
    id: 139,
    name: "Mauritania",
    iso3: "MRT",
    currency: "MRO",
    currency_symbol: "MRU",
  },
  {
    id: 140,
    name: "Mauritius",
    iso3: "MUS",
    currency: "MUR",
    currency_symbol: "₨",
  },
  {
    id: 141,
    name: "Mayotte",
    iso3: "MYT",
    currency: "EUR",
    currency_symbol: "€",
  },
  {
    id: 142,
    name: "Mexico",
    iso3: "MEX",
    currency: "MXN",
    currency_symbol: "$",
  },
  {
    id: 143,
    name: "Micronesia",
    iso3: "FSM",
    currency: "USD",
    currency_symbol: "$",
  },
  {
    id: 144,
    name: "Moldova",
    iso3: "MDA",
    currency: "MDL",
    currency_symbol: "L",
  },
  {
    id: 145,
    name: "Monaco",
    iso3: "MCO",
    currency: "EUR",
    currency_symbol: "€",
  },
  {
    id: 146,
    name: "Mongolia",
    iso3: "MNG",
    currency: "MNT",
    currency_symbol: "₮",
  },
  {
    id: 147,
    name: "Montenegro",
    iso3: "MNE",
    currency: "EUR",
    currency_symbol: "€",
  },
  {
    id: 148,
    name: "Montserrat",
    iso3: "MSR",
    currency: "XCD",
    currency_symbol: "$",
  },
  {
    id: 149,
    name: "Morocco",
    iso3: "MAR",
    currency: "MAD",
    currency_symbol: "DH",
  },
  {
    id: 150,
    name: "Mozambique",
    iso3: "MOZ",
    currency: "MZN",
    currency_symbol: "MT",
  },
  {
    id: 151,
    name: "Myanmar",
    iso3: "MMR",
    currency: "MMK",
    currency_symbol: "K",
  },
  {
    id: 152,
    name: "Namibia",
    iso3: "NAM",
    currency: "NAD",
    currency_symbol: "$",
  },
  {
    id: 153,
    name: "Nauru",
    iso3: "NRU",
    currency: "AUD",
    currency_symbol: "$",
  },
  {
    id: 154,
    name: "Nepal",
    iso3: "NPL",
    currency: "NPR",
    currency_symbol: "₨",
  },
  {
    id: 156,
    name: "Netherlands",
    iso3: "NLD",
    currency: "EUR",
    currency_symbol: "€",
  },
  {
    id: 157,
    name: "New Caledonia",
    iso3: "NCL",
    currency: "XPF",
    currency_symbol: "₣",
  },
  {
    id: 158,
    name: "New Zealand",
    iso3: "NZL",
    currency: "NZD",
    currency_symbol: "$",
  },
  {
    id: 159,
    name: "Nicaragua",
    iso3: "NIC",
    currency: "NIO",
    currency_symbol: "C$",
  },
  {
    id: 160,
    name: "Niger",
    iso3: "NER",
    currency: "XOF",
    currency_symbol: "CFA",
  },
  {
    id: 161,
    name: "Nigeria",
    iso3: "NGA",
    currency: "NGN",
    currency_symbol: "₦",
  },
  {
    id: 162,
    name: "Niue",
    iso3: "NIU",
    currency: "NZD",
    currency_symbol: "$",
  },
  {
    id: 163,
    name: "Norfolk Island",
    iso3: "NFK",
    currency: "AUD",
    currency_symbol: "$",
  },
  {
    id: 115,
    name: "North Korea",
    iso3: "PRK",
    currency: "KPW",
    currency_symbol: "₩",
  },
  {
    id: 164,
    name: "Northern Mariana Islands",
    iso3: "MNP",
    currency: "USD",
    currency_symbol: "$",
  },
  {
    id: 165,
    name: "Norway",
    iso3: "NOR",
    currency: "NOK",
    currency_symbol: "kr",
  },
  {
    id: 166,
    name: "Oman",
    iso3: "OMN",
    currency: "OMR",
    currency_symbol: ".ع.ر",
  },
  {
    id: 167,
    name: "Pakistan",
    iso3: "PAK",
    currency: "PKR",
    currency_symbol: "₨",
  },
  {
    id: 168,
    name: "Palau",
    iso3: "PLW",
    currency: "USD",
    currency_symbol: "$",
  },
  {
    id: 169,
    name: "Palestinian Territory Occupied",
    iso3: "PSE",
    currency: "ILS",
    currency_symbol: "₪",
  },
  {
    id: 170,
    name: "Panama",
    iso3: "PAN",
    currency: "PAB",
    currency_symbol: "B/.",
  },
  {
    id: 171,
    name: "Papua new Guinea",
    iso3: "PNG",
    currency: "PGK",
    currency_symbol: "K",
  },
  {
    id: 172,
    name: "Paraguay",
    iso3: "PRY",
    currency: "PYG",
    currency_symbol: "₲",
  },
  {
    id: 173,
    name: "Peru",
    iso3: "PER",
    currency: "PEN",
    currency_symbol: "S/.",
  },
  {
    id: 174,
    name: "Philippines",
    iso3: "PHL",
    currency: "PHP",
    currency_symbol: "₱",
  },
  {
    id: 175,
    name: "Pitcairn Island",
    iso3: "PCN",
    currency: "NZD",
    currency_symbol: "$",
  },
  {
    id: 176,
    name: "Poland",
    iso3: "POL",
    currency: "PLN",
    currency_symbol: "zł",
  },
  {
    id: 177,
    name: "Portugal",
    iso3: "PRT",
    currency: "EUR",
    currency_symbol: "€",
  },
  {
    id: 178,
    name: "Puerto Rico",
    iso3: "PRI",
    currency: "USD",
    currency_symbol: "$",
  },
  {
    id: 179,
    name: "Qatar",
    iso3: "QAT",
    currency: "QAR",
    currency_symbol: "ق.ر",
  },
  {
    id: 180,
    name: "Reunion",
    iso3: "REU",
    currency: "EUR",
    currency_symbol: "€",
  },
  {
    id: 181,
    name: "Romania",
    iso3: "ROU",
    currency: "RON",
    currency_symbol: "lei",
  },
  {
    id: 182,
    name: "Russia",
    iso3: "RUS",
    currency: "RUB",
    currency_symbol: "₽",
  },
  {
    id: 183,
    name: "Rwanda",
    iso3: "RWA",
    currency: "RWF",
    currency_symbol: "FRw",
  },
  {
    id: 184,
    name: "Saint Helena",
    iso3: "SHN",
    currency: "SHP",
    currency_symbol: "£",
  },
  {
    id: 185,
    name: "Saint Kitts And Nevis",
    iso3: "KNA",
    currency: "XCD",
    currency_symbol: "$",
  },
  {
    id: 186,
    name: "Saint Lucia",
    iso3: "LCA",
    currency: "XCD",
    currency_symbol: "$",
  },
  {
    id: 187,
    name: "Saint Pierre and Miquelon",
    iso3: "SPM",
    currency: "EUR",
    currency_symbol: "€",
  },
  {
    id: 188,
    name: "Saint Vincent And The Grenadines",
    iso3: "VCT",
    currency: "XCD",
    currency_symbol: "$",
  },
  {
    id: 189,
    name: "Saint-Barthelemy",
    iso3: "BLM",
    currency: "EUR",
    currency_symbol: "€",
  },
  {
    id: 190,
    name: "Saint-Martin (French part)",
    iso3: "MAF",
    currency: "EUR",
    currency_symbol: "€",
  },
  {
    id: 191,
    name: "Samoa",
    iso3: "WSM",
    currency: "WST",
    currency_symbol: "SAT",
  },
  {
    id: 192,
    name: "San Marino",
    iso3: "SMR",
    currency: "EUR",
    currency_symbol: "€",
  },
  {
    id: 193,
    name: "Sao Tome and Principe",
    iso3: "STP",
    currency: "STD",
    currency_symbol: "Db",
  },
  {
    id: 194,
    name: "Saudi Arabia",
    iso3: "SAU",
    currency: "SAR",
    currency_symbol: "﷼",
  },
  {
    id: 195,
    name: "Senegal",
    iso3: "SEN",
    currency: "XOF",
    currency_symbol: "CFA",
  },
  {
    id: 196,
    name: "Serbia",
    iso3: "SRB",
    currency: "RSD",
    currency_symbol: "din",
  },
  {
    id: 197,
    name: "Seychelles",
    iso3: "SYC",
    currency: "SCR",
    currency_symbol: "SRe",
  },
  {
    id: 198,
    name: "Sierra Leone",
    iso3: "SLE",
    currency: "SLL",
    currency_symbol: "Le",
  },
  {
    id: 199,
    name: "Singapore",
    iso3: "SGP",
    currency: "SGD",
    currency_symbol: "$",
  },
  {
    id: 250,
    name: "Sint Maarten (Dutch part)",
    iso3: "SXM",
    currency: "ANG",
    currency_symbol: "ƒ",
  },
  {
    id: 200,
    name: "Slovakia",
    iso3: "SVK",
    currency: "EUR",
    currency_symbol: "€",
  },
  {
    id: 201,
    name: "Slovenia",
    iso3: "SVN",
    currency: "EUR",
    currency_symbol: "€",
  },
  {
    id: 202,
    name: "Solomon Islands",
    iso3: "SLB",
    currency: "SBD",
    currency_symbol: "Si$",
  },
  {
    id: 203,
    name: "Somalia",
    iso3: "SOM",
    currency: "SOS",
    currency_symbol: "Sh.so.",
  },
  {
    id: 204,
    name: "South Africa",
    iso3: "ZAF",
    currency: "ZAR",
    currency_symbol: "R",
  },
  {
    id: 205,
    name: "South Georgia",
    iso3: "SGS",
    currency: "GBP",
    currency_symbol: "£",
  },
  {
    id: 116,
    name: "South Korea",
    iso3: "KOR",
    currency: "KRW",
    currency_symbol: "₩",
  },
  {
    id: 206,
    name: "South Sudan",
    iso3: "SSD",
    currency: "SSP",
    currency_symbol: "£",
  },
  {
    id: 207,
    name: "Spain",
    iso3: "ESP",
    currency: "EUR",
    currency_symbol: "€",
  },
  {
    id: 208,
    name: "Sri Lanka",
    iso3: "LKA",
    currency: "LKR",
    currency_symbol: "Rs",
  },
  {
    id: 209,
    name: "Sudan",
    iso3: "SDN",
    currency: "SDG",
    currency_symbol: ".س.ج",
  },
  {
    id: 210,
    name: "Suriname",
    iso3: "SUR",
    currency: "SRD",
    currency_symbol: "$",
  },
  {
    id: 211,
    name: "Svalbard And Jan Mayen Islands",
    iso3: "SJM",
    currency: "NOK",
    currency_symbol: "kr",
  },
  {
    id: 212,
    name: "Swaziland",
    iso3: "SWZ",
    currency: "SZL",
    currency_symbol: "E",
  },
  {
    id: 213,
    name: "Sweden",
    iso3: "SWE",
    currency: "SEK",
    currency_symbol: "kr",
  },
  {
    id: 214,
    name: "Switzerland",
    iso3: "CHE",
    currency: "CHF",
    currency_symbol: "CHf",
  },
  {
    id: 215,
    name: "Syria",
    iso3: "SYR",
    currency: "SYP",
    currency_symbol: "LS",
  },
  {
    id: 216,
    name: "Taiwan",
    iso3: "TWN",
    currency: "TWD",
    currency_symbol: "$",
  },
  {
    id: 217,
    name: "Tajikistan",
    iso3: "TJK",
    currency: "TJS",
    currency_symbol: "SM",
  },
  {
    id: 218,
    name: "Tanzania",
    iso3: "TZA",
    currency: "TZS",
    currency_symbol: "TSh",
  },
  {
    id: 219,
    name: "Thailand",
    iso3: "THA",
    currency: "THB",
    currency_symbol: "฿",
  },
  {
    id: 17,
    name: "The Bahamas",
    iso3: "BHS",
    currency: "BSD",
    currency_symbol: "B$",
  },
  {
    id: 220,
    name: "Togo",
    iso3: "TGO",
    currency: "XOF",
    currency_symbol: "CFA",
  },
  {
    id: 221,
    name: "Tokelau",
    iso3: "TKL",
    currency: "NZD",
    currency_symbol: "$",
  },
  {
    id: 222,
    name: "Tonga",
    iso3: "TON",
    currency: "TOP",
    currency_symbol: "$",
  },
  {
    id: 223,
    name: "Trinidad And Tobago",
    iso3: "TTO",
    currency: "TTD",
    currency_symbol: "$",
  },
  {
    id: 224,
    name: "Tunisia",
    iso3: "TUN",
    currency: "TND",
    currency_symbol: "ت.د",
  },
  {
    id: 225,
    name: "Turkey",
    iso3: "TUR",
    currency: "TRY",
    currency_symbol: "₺",
  },
  {
    id: 226,
    name: "Turkmenistan",
    iso3: "TKM",
    currency: "TMT",
    currency_symbol: "T",
  },
  {
    id: 227,
    name: "Turks And Caicos Islands",
    iso3: "TCA",
    currency: "USD",
    currency_symbol: "$",
  },
  {
    id: 228,
    name: "Tuvalu",
    iso3: "TUV",
    currency: "AUD",
    currency_symbol: "$",
  },
  {
    id: 229,
    name: "Uganda",
    iso3: "UGA",
    currency: "UGX",
    currency_symbol: "USh",
  },
  {
    id: 230,
    name: "Ukraine",
    iso3: "UKR",
    currency: "UAH",
    currency_symbol: "₴",
  },
  {
    id: 231,
    name: "United Arab Emirates",
    iso3: "ARE",
    currency: "AED",
    currency_symbol: "إ.د",
  },
  {
    id: 232,
    name: "United Kingdom",
    iso3: "GBR",
    currency: "GBP",
    currency_symbol: "£",
  },
  {
    id: 233,
    name: "United States",
    iso3: "USA",
    currency: "USD",
    currency_symbol: "$",
  },
  {
    id: 234,
    name: "United States Minor Outlying Islands",
    iso3: "UMI",
    currency: "USD",
    currency_symbol: "$",
  },
  {
    id: 235,
    name: "Uruguay",
    iso3: "URY",
    currency: "UYU",
    currency_symbol: "$",
  },
  {
    id: 236,
    name: "Uzbekistan",
    iso3: "UZB",
    currency: "UZS",
    currency_symbol: "лв",
  },
  {
    id: 237,
    name: "Vanuatu",
    iso3: "VUT",
    currency: "VUV",
    currency_symbol: "VT",
  },
  {
    id: 238,
    name: "Vatican City State (Holy See)",
    iso3: "VAT",
    currency: "EUR",
    currency_symbol: "€",
  },
  {
    id: 239,
    name: "Venezuela",
    iso3: "VEN",
    currency: "VEF",
    currency_symbol: "Bs",
  },
  {
    id: 240,
    name: "Vietnam",
    iso3: "VNM",
    currency: "VND",
    currency_symbol: "₫",
  },
  {
    id: 241,
    name: "Virgin Islands (British)",
    iso3: "VGB",
    currency: "USD",
    currency_symbol: "$",
  },
  {
    id: 242,
    name: "Virgin Islands (US)",
    iso3: "VIR",
    currency: "USD",
    currency_symbol: "$",
  },
  {
    id: 243,
    name: "Wallis And Futuna Islands",
    iso3: "WLF",
    currency: "XPF",
    currency_symbol: "₣",
  },
  {
    id: 244,
    name: "Western Sahara",
    iso3: "ESH",
    currency: "MAD",
    currency_symbol: "MAD",
  },
  {
    id: 245,
    name: "Yemen",
    iso3: "YEM",
    currency: "YER",
    currency_symbol: "﷼",
  },
  {
    id: 246,
    name: "Zambia",
    iso3: "ZMB",
    currency: "ZMW",
    currency_symbol: "ZK",
  },
  {
    id: 247,
    name: "Zimbabwe",
    iso3: "ZWE",
    currency: "ZWL",
    currency_symbol: "$",
  },
];
