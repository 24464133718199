const category = [
  "Home Decor & Festive Needs",
  "Sports & Fitness",
  "Gaming",
  "Automotive",
  "Health & Personal Care Appliances",
  "Food & Nutrition",
  "Watches",
  "Cameras & Accessories",
  "Pens & Stationery",
  "Home & Kitchen",
  "Pet Supplies",
  "Toys & School Supplies",
  "Kitchen & Dining",
  "Eyewear",
  "Furniture",
  "Home Entertainment",
  "Computers",
  "Home Improvement",
  "Home Furnishing",
  "Bags, Wallets & Belts",
  "Automation & Robotics",
  "Beauty and Personal Care",
  "Footwear",
  "Mobiles & Accessories",
  "Baby Care",
  "Household Supplies",
  "Sunglasses",
  "Jewellery",
  "Treppe Bellies",
  "Clothing",
  "D&D Women Flats",
  "Tools & Hardware",
  "Zikrak Exim Women Wedges",
];
const getCategory = () => {
  return category;
};
export default getCategory;
